import { ButtonHTMLAttributes } from 'react';

export default function PrimaryButton({ className = '', disabled, children, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button
            {...props}
            className={
                `items-center content-center px-4 py-0 bg-bonza-lilac border border-transparent rounded-3xl text-s
                text-bonza-dark tracking-widest hover:text-white hover:bg-bonza-dark focus:bg-bonza-dark
                active:bg-bonza-dark focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out
                 duration-150 ${disabled && 'opacity-25'}` + className
            }
            disabled={disabled}
        >
            {children}
        </button>
    );
}
